<template>
  <section v-if="selectedCampaign" class="main-wrap" :class="{ 'is-generating': isGeneratingCSV }">
    <header class="inner-page-header">
      <div class="header-title-wrap">
        <router-link
          v-if="selectedClient"
          class="client-profil-wrap"
          :to="{
            name: 'CampaignsList',
            params: { clientId: selectedClient.id },
          }"
        >
          <div class="overlay-back">
            <svg width="7" height="12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M5.768 12c.317 0 .621-.126.864-.366.475-.492.475-1.301 0-1.793L2.945 6l3.7-3.839c.474-.492.474-1.3 0-1.793a1.195 1.195 0 0 0-1.729 0L.353 5.105C.122 5.345 0 5.66 0 6c0 .341.134.657.353.897l4.551 4.736c.244.24.56.366.864.366z"
                fill="#fff"
              />
            </svg>
          </div>
          <client-logo v-if="selectedClient" :client="selectedClient" class-name="is-medium"></client-logo>
        </router-link>

        <div class="header-title">
          <h1 class="big-title" @click.prevent="enterLiveEditMode" @blur="saveLiveEdit">
            {{ selectedCampaign.name }}
          </h1>
          <h2 class="small-title">Project</h2>
        </div>
      </div>

      <div class="action-wrap">

        <a v-if="currentCampaign && currentCampaign.mediaplan" :href="currentCampaign.mediaplan" target="_blank" class="small-outline-bt">
          <p>Media plan</p>
        </a>

        <router-link
          v-if="selectedClient && selectedCampaign"
          :to="{
            name: 'EditCampaign',
            params: {
              clientId: selectedClient.id,
              campaignId: selectedCampaign.id,
            },
          }"
        >
          <div class="settings-wrap">
            <div class="v-line"></div>
            <div class="v-line"></div>
            <div class="v-line"></div>
            <div class="bullet left"></div>
            <div class="bullet center"></div>
            <div class="bullet right"></div>
          </div>
        </router-link>
      </div>
    </header>

    <div v-if="selectedClient && selectedCampaign && appDomain && adzList" class="campaign-wrap">
      <div v-if="selectedCampaign.platforms.includes('facebook')" class="platform-wrap">
        <div class="platform">
          <img
            alt="Client logo"
            src="https://firebasestorage.googleapis.com/v0/b/showadz-prod-6a02e.appspot.com/o/facebook-instagram-logos.png?alt=media&token=84cfd196-dbbc-4201-b2b5-8187b3aa0517"
          />
          <p>Facebook / Instagram</p>
          <p v-if="adzList.length > 1" class="spacer">|</p>
          <p v-if="adzList.length > 1">{{ adzList.length }} ad{{ adzList.length > 1 ? 's' : '' }}</p>
          <p v-if="$route.params && adzListReady && adzListReady.length" class="spacer">|</p>
          <router-link
            v-if="$route.params && adzListReady && adzListReady.length"
            class="add-format"
            :to="{
              name: 'facebook-preview-all',
              params: { clientId: $route.params.clientId, campaignId: $route.params.campaignId },
            }"
          >
            <p class="link-style">Preview all ads</p>
          </router-link>

          <div v-if="account && adzListReady.length && account[0].usersRoles[user.id].role !== 'guest'">
            <div
              v-if="selectedClient.FBBusinessManager && selectedClient.FBBusinessManager.access_token && selectedClient.FBBusinessManager.ad_account_id"
              class="business-manager-button connected"
              @click.prevent="toogleBusinessManagerPanel(true, 'fetchAdAccountInfos')"
            >
              <div class="state">
                <p>Business Manager</p>
              </div>
              <div class="action"><p>Push ads</p></div>
            </div>
            <div
              v-else
              class="business-manager-button"
              :class="{
                connected:
                  selectedClient.FBBusinessManager && selectedClient.FBBusinessManager.access_token && selectedClient.FBBusinessManager.ad_account_id,
              }"
              @click.prevent="toogleBusinessManagerPanel(true, 'getUserToken')"
            >
              <div class="state">
                <p>Business Manager</p>
              </div>
              <div class="action"><p>Push ads</p></div>
            </div>
          </div>
        </div>

        <div class="wrap-right">
          <img class="show-type" :class="{ actif: preferedViewMode === 'list' }" alt="show list" src="@/assets/img/show-list.svg" @click="showAdzList" />
          <img
            class="show-type"
            :class="{ actif: preferedViewMode === 'thumb' }"
            alt="show block"
            src="@/assets/img/show-block.svg"
            @click="showAdzThumbnail"
          />
          <router-link
            class="add-format"
            :to="{
              name: 'AddFacebookAds',
              params: {
                clientId: selectedClient.id,
                campaignId: selectedCampaign.id,
              },
            }"
          >
            <p>Create Ads</p>
            <div class="add">
              <img alt="Plus" src="@/assets/img/plus-icon.svg" />
            </div>
          </router-link>
        </div>
      </div>

      <div v-if="selectedCampaign.platforms.includes('facebook') && adzList && adzList.length < 1" class="still-no-ad">
        <router-link
          :to="{
            name: 'AddFacebookAds',
            params: {
              clientId: selectedClient.id,
              campaignId: selectedCampaign.id,
            },
          }"
        >
          <p>Create Ads</p>
        </router-link>
      </div>

      <div
        v-if="selectedCampaign.platforms.includes('facebook') && selectedClient && selectedCampaign && adzList && adzList.length > 0"
        class="ads-wrap"
        :class="{ displaylist: preferedViewMode === 'list' }"
      >
        <!---------------------COMPONENT AD START--------------------->
        <div
          v-for="(adz, index) in adzList"
          :key="index"
          class="ad-wrap toggle-options"
          :class="{ ready: adz.isReady, active: selectedAdz.includes(adz.id) }"
        >
          <div class="ad-img-wrap">
            <div class="over-wrap">
              <div class="share-selection" :class="{ active: selectedAdz.includes(adz.id) }" @click="activeLink(adz)">
                <img alt="Check" src="@/assets/img/check-icon-grey.svg" />
              </div>
              <contextual-toggle-edit
                :client="adz"
                :actions="['edit', 'duplicate', 'archive', 'delete']"
                :edit-action="editAdz"
                :duplicate-action="duplicateAdz"
                :archive-action="archiveAdz"
                :delete-action="deleteAdz"
              ></contextual-toggle-edit>
              <div class="actions-wrap">
                <router-link
                  v-if="['single', 'multiple'].includes(adz.type)"
                  class="action"
                  :to="{
                    name: 'Facebook',
                    params: {
                      clientId: selectedClient.id,
                      campaignId: selectedCampaign.id,
                      adzId: adz.id,
                    },
                  }"
                >
                  <p>Show</p>
                </router-link>
                <router-link
                  v-else
                  class="action"
                  :to="{ name: 'Stories', params: { clientId: selectedClient.id, campaignId: selectedCampaign.id, adzId: adz.id } }"
                >
                  <p>Show</p>
                </router-link>
                <router-link
                  v-if="adz.type === 'single'"
                  class="action"
                  :to="{
                    name: 'CreateFacebookAds',
                    params: {
                      clientId: selectedClient.id,
                      campaignId: selectedCampaign.id,
                      adzId: adz.id,
                    },
                  }"
                >
                  <p>Edit</p>
                </router-link>
                <router-link
                  v-if="adz.type === 'stories'"
                  class="action"
                  :to="{
                    name: 'CreateFacebookStories',
                    params: {
                      clientId: selectedClient.id,
                      campaignId: selectedCampaign.id,
                      adzId: adz.id,
                    },
                  }"
                >
                  <p>Edit</p>
                </router-link>
                <router-link
                  v-if="adz.type === 'multiple'"
                  class="action"
                  :to="{
                    name: 'CreateFacebookCarrousel',
                    params: {
                      clientId: selectedClient.id,
                      campaignId: selectedCampaign.id,
                      adzId: adz.id,
                    },
                  }"
                >
                  <p>Edit</p>
                </router-link>
              </div>
            </div>
            <div class="img-wrap">
              <adz-image :adz="adz" :app-domain="appDomain"></adz-image>
            </div>
          </div>

          <footer>
            <div class="infos">
              <p class="campaing-name">{{ adz.name }}</p>
              <br />
              <div class="comments" :class="{ fade: getAdzTotalComments(adz) === 0 }">
                <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M13.8135 4.93129e-05C14.4627 4.93129e-05 15 0.520328 15 1.14899V8.17203C15 8.80068 14.4627 9.3221 13.8135 9.3221H12.8303V11.5461C12.8303 11.7175 12.7307 11.8741 12.5725 11.9513C12.4148 12.0284 12.2256 12.0131 12.0832 11.9115L8.45683 9.32205H1.18652C0.537312 9.32205 0 8.80064 0 8.17198V1.14894C0 0.520293 0.537297 0 1.18652 0L13.8135 4.93129e-05Z"
                    fill="#6934FF"
                  />
                </svg>
                <p>{{ getAdzTotalComments(adz) }}</p>
              </div>
            </div>

            <div class="right">
              <div class="status">
                <div v-if="adz.isApproved" class="approved">
                  <img alt="Check" src="@/assets/img/check-icon.svg" />
                </div>
              </div>

              <div class="ad-type">
                <div v-if="adz.type === 'single'"><img alt="Single" src="@/assets/img/fb-simple-produit.svg" /></div>
                <div v-if="adz.type === 'multiple'"><img alt="Multiple" src="@/assets/img/fb-carrousel.svg" /></div>
                <div v-if="adz.type === 'stories'"><img alt="Stories" src="@/assets/img/story-icon.svg" /></div>
              </div>

              <div class="action-list-wrap">
                <div class="tool-tip-wrap">
                  <div class="tool-tip"><p>Show</p></div>
                  <router-link
                    class="action-list"
                    :to="{
                      name: 'Facebook',
                      params: {
                        clientId: selectedClient.id,
                        campaignId: selectedCampaign.id,
                        adzId: adz.id,
                      },
                    }"
                  >
                    <img alt="Show icon" src="@/assets/img/see-icon.svg" />
                  </router-link>
                </div>

                <div class="tool-tip-wrap">
                  <div class="tool-tip"><p>Edit</p></div>
                  <router-link
                    v-if="adz.type === 'single'"
                    class="action-list"
                    :to="{
                      name: 'CreateFacebookAds',
                      params: {
                        clientId: selectedClient.id,
                        campaignId: selectedCampaign.id,
                        adzId: adz.id,
                      },
                    }"
                  >
                    <img alt="Show icon" src="@/assets/img/edit-icon.svg" />
                  </router-link>
                  <router-link
                    v-if="adz.type === 'stories'"
                    class="action-list"
                    :to="{
                      name: 'CreateFacebookStories',
                      params: {
                        clientId: selectedClient.id,
                        campaignId: selectedCampaign.id,
                        adzId: adz.id,
                      },
                    }"
                  >
                    <img alt="Show icon" src="@/assets/img/edit-icon.svg" />
                  </router-link>
                  <router-link
                    v-if="adz.type === 'multiple'"
                    class="action-list"
                    :to="{
                      name: 'CreateFacebookCarrousel',
                      params: {
                        clientId: selectedClient.id,
                        campaignId: selectedCampaign.id,
                        adzId: adz.id,
                      },
                    }"
                  >
                    <img alt="Show icon" src="@/assets/img/edit-icon.svg" />
                  </router-link>
                </div>

                <div class="tool-tip-wrap">
                  <div class="tool-tip"><p>Duplicate</p></div>
                  <img alt="dupplicate" class="dupplicate action-list" src="@/assets/img/dupplicate-icon.svg" @click.prevent="duplicateAdz(adz)" />
                </div>

                <div class="tool-tip-wrap">
                  <div class="tool-tip"><p>Delete</p></div>
                  <img alt="delete" class="delete action-list" src="@/assets/img/delete-icon.svg" @click.prevent="deleteAdz(adz)" />
                </div>
              </div>
            </div>
          </footer>
        </div>
        <!---------------------COMPONENT AD END--------------------->
      </div>
      <!--ads-wrap-->

      <div v-if="selectedCampaign.platforms.includes('googleAds') && selectedClient && selectedCampaign && googleAdzList" class="platform-wrap">
        <div class="platform">
          <img alt="Client logo" :src="platforms.find((item) => item.slug === 'googleAds').icon" />
          <p>{{ platforms.find((item) => item.slug === 'googleAds').name }}</p>
          <p v-if="googleAdzList && googleAdzList.length > 0" class="spacer">|</p>
          <p v-if="googleAdzList && googleAdzList.length > 0">{{ googleAdzList.length }} Campaigns</p>
          <p v-if="googleAdzList && googleAdzList.length > 0" class="spacer">|</p>
          <router-link
            v-if="$route.params && googleAdzList && googleAdzList.length > 0"
            class="add-format"
            :to="{ name: 'google', params: { clientId: $route.params.clientId, campaignId: $route.params.campaignId } }"
          >
            <p class="link-style">Preview all ads</p>
          </router-link>
          <div v-if="$route.params && googleAdzList && googleAdzList.length > 0" class="business-manager-button" @click.prevent="exportGoogleAdz()">
            <div class="state">
              <p>Google Ads Editor</p>
            </div>
            <div class="action"><p>Export</p></div>
          </div>
        </div>

        <div class="right">
          <a v-if="lastestGoogleAdsXslFile" :href="lastestGoogleAdsXslFile" target="_blank">
            <img class="download-icon" alt="Show icon" src="@/assets/img/download-icon.svg" />
          </a>
          <router-link
            v-if="$route.params"
            class="add-format"
            :to="{
              name: 'CreateGoogle',
              params: { clientId: $route.params.clientId, campaignId: $route.params.campaignId },
            }"
          >
            <p v-if="googleAdzList.length">Edit Google Ads</p>
            <p v-else>Create Ads</p>
            <div class="add">
              <img alt="Plus" src="@/assets/img/plus-icon.svg" />
            </div>
          </router-link>
        </div>
      </div>

      <!-- AdzLoop GOOGLE ADS -->
      <div
        v-if="selectedCampaign.platforms.includes('googleAds') && selectedClient && selectedCampaign && googleAdzList && googleAdzList.length > 0"
        class="ads-wrap google-ads-wrap"
      >
        <!---------------------COMPONENT AD START--------------------->
        <div
          v-for="(adz, index) in googleAdzList"
          :key="index"
          class="ad-wrap toggle-options"
          :class="{ ready: adz.isReady, active: selectedAdz.includes(adz.id) }"
          @click.prevent="
            $router.push({
              name: 'google',
              params: { clientId: selectedClient.id, campaignId: selectedCampaign.id },
              query: { selectCampaign: adz.id },
            })
          "
        >
          <div class="infos">
            <div class="campaing-infos name">
              <svg width="18" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M3 1.984V10l2.484-1.5L8.016 10V1.984H3zM15 .016c.531 0 1 .203 1.406.609.407.375.61.828.61 1.36v16.03c0 .532-.203 1-.61 1.407a2.011 2.011 0 0 1-1.406.562H3c-.531 0-1-.187-1.406-.562-.407-.406-.61-.875-.61-1.406V1.984c0-.53.203-.984.61-1.359C2 .219 2.469.015 3 .015h12z"
                  fill="url(#paint0_linear)"
                />
                <defs>
                  <linearGradient id="paint0_linear" x1="-.226" y1="9.601" x2="19.133" y2="9.601" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#2E7DF2" />
                    <stop offset="1" stop-color="#8A23F1" />
                  </linearGradient>
                </defs>
              </svg>
              <p>{{ adz.name }}</p>
            </div>
            <div class="campaing-infos">
              <svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M2.578 5.39c.406.407.875.61 1.406.61.532 0 1-.203 1.407-.61.406-.406.609-.874.609-1.406 0-.53-.203-.984-.61-1.359-.406-.406-.874-.61-1.406-.61-.53 0-1 .204-1.406.61a1.852 1.852 0 0 0-.562 1.36c0 .53.187 1 .562 1.406zM17.016 0c.28 0 .515.094.703.281A.954.954 0 0 1 18 .984v6c0 .282-.094.532-.281.75a.954.954 0 0 1-.703.282H.984a.954.954 0 0 1-.703-.282A1.12 1.12 0 0 1 0 6.984v-6A.96.96 0 0 1 .281.281.954.954 0 0 1 .984 0h16.032zM2.578 15.422a2.01 2.01 0 0 0 1.406.562c.532 0 1-.187 1.407-.562.406-.406.609-.875.609-1.406 0-.532-.203-1-.61-1.407-.406-.406-.874-.609-1.406-.609-.53 0-1 .203-1.406.61a2.011 2.011 0 0 0-.562 1.406c0 .53.187 1 .562 1.406zm14.438-5.438c.28 0 .515.11.703.329a.954.954 0 0 1 .281.703v6a.955.955 0 0 1-.281.703.955.955 0 0 1-.703.281H.984a.954.954 0 0 1-.703-.281.954.954 0 0 1-.281-.703v-6c0-.282.094-.516.281-.704a.888.888 0 0 1 .703-.328h16.032z"
                  fill="url(#paint0_linear)"
                />
                <defs>
                  <linearGradient id="paint0_linear" x1="-1.358" y1="8.64" x2="20.377" y2="8.64" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#2E7DF2" />
                    <stop offset="1" stop-color="#8A23F1" />
                  </linearGradient>
                </defs>
              </svg>
              <p>{{ adz.adGroups.length }} ad groups</p>
            </div>
            <div class="campaing-infos">
              <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M13.938 16.516h2.109l-5.11-13.032H9.063l-5.11 13.032h2.11l1.125-3h5.625l1.124 3zm4.078-16.5c.53 0 .984.203 1.359.609.406.375.61.828.61 1.36v16.03c0 .532-.204 1-.61 1.407a1.852 1.852 0 0 1-1.36.562H1.986c-.532 0-1-.187-1.407-.562a2.012 2.012 0 0 1-.562-1.406V1.984c0-.53.187-.984.562-1.359.406-.406.875-.61 1.406-.61h16.032zM7.938 11.5L10 5.969l2.063 5.531H7.936z"
                  fill="url(#paint0_linear)"
                />
                <defs>
                  <linearGradient id="paint0_linear" x1="-1.491" y1="9.601" x2="22.622" y2="9.601" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#2E7DF2" />
                    <stop offset="1" stop-color="#8A23F1" />
                  </linearGradient>
                </defs>
              </svg>
              <p>
                {{
                  adz.keywordsAndType
                    ? Object.keys(adz.keywordsAndType).reduce((acc, e) => {
                        return (acc = acc + adz.keywordsAndType[e].length)
                      }, 0)
                    : 0
                }}
                Keywords
              </p>
            </div>
            <div class="preview-google-ad">
              <img alt="Show icon" src="@/assets/img/see-icon.svg" />
            </div>
          </div>
        </div>
        <!---------------------COMPONENT AD END--------------------->
      </div>

      <div v-else-if="selectedCampaign.platforms.includes('googleAds')" class="still-no-ad">
        <router-link
          :to="{
            name: 'CreateGoogle',
            params: { clientId: $route.params.clientId, campaignId: $route.params.campaignId },
          }"
        >
          <p>Create Ads</p>
        </router-link>
      </div>

      <!-- Facebook Manager Side Panel -->
      <div v-if="openBusinessManagerPanel" class="business-manager-synch-panel" :class="{ 'is-loading': isLoading }">
        <div class="overlay" @click.prevent="toogleBusinessManagerPanel(false, 'reset')"></div>

        <!-- Synch :: Select Ad Account -->
        <div v-if="businessManagerPanelStep === 'select-ad-account'" class="panel">
          <Loader v-if="isLoading" :label="'Fetching your Ads accounts list'"></Loader>

          <header>
            <div class="title">
              <img src="@/assets/img/business-manager.png" alt="Facebook Business Manager" />
              <p>Business Manager</p>
            </div>
            <img src="@/assets/img/close.svg" alt="close" class="close" @click.prevent="toogleBusinessManagerPanel(false, 'reset')" />
          </header>

          <h2 class="small-title">Select an ads account</h2>

          <div class="search-filter-results">
            <input v-model="currentFilterSearch" type="text" class="search" placeholder="Search..." />
          </div>

          <div v-if="selectedClient.FBBusinessManager && selectedClient.FBBusinessManager.ad_accounts_list.length" class="ads-selection">
            <div
              v-for="(account, index) in adAccountsListOrdered.filter((i) => {
                return currentFilterSearch ? i.name.toLowerCase().includes(currentFilterSearch.toLowerCase()) : i
              })"
              :key="index"
              class="row"
              @click.prevent="selectFBAdAccountId(account.id)"
            >
              <div class="graphic">
                <img src="@/assets/img/account-icon.svg" alt="ads" />
              </div>
              <a href="#" class="row-name" :title="account.name.trim()">{{ account.name.trim() }}</a>
              <input type="radio" class="input-position" :checked="selectedFBAdAccountId === account.id" />
            </div>
          </div>

          <div v-else class="ads-selection">
            <div class="fb-log-error">
              <img src="@/assets/img/error-alert.svg" alt="alert" />
              <p>
                There is no valid adAccount for this user. This might be because there is no Facebook Page related to your ads accounts. Make sure your
                account is properly setup.
              </p>
            </div>
          </div>

          <button type="submit" class="bt" @click.prevent="syncSelectedAdAccountId()">
            <div class="bt-text">
              <p>Next</p>
              <img alt="Plus" class="bt-img" src="@/assets/img/arrow-right.svg" />
            </div>
          </button>
        </div>

        <!-- Synch :: Select Ad Account Page Id -->
        <div v-if="businessManagerPanelStep === 'fetchAdAccountInfos'" class="panel">
          <Loader v-if="isLoading" :label="'Fetching your Ad account pages list'"></Loader>

          <header>
            <div class="title" @click.prevent="businessManagerPanelGoTo('select-ad-account')">
              <img src="@/assets/img/back-icon.svg" alt="Back" />
              <p>Back</p>
            </div>
            <img src="@/assets/img/close.svg" alt="close" class="close" @click.prevent="toogleBusinessManagerPanel(false, 'reset')" />
          </header>

          <h2 class="small-title">Select a page</h2>
          <div class="search-filter-results">
            <input v-model="currentFilterSearch" type="text" class="search" placeholder="Search..." />
          </div>

          <div v-if="selectedClient.FBBusinessManager.user_accounts_list.length" class="ads-selection">
            <!-- Suggested -->
            <p v-if="matchingAccountPage" class="suggested-label">Suggested page:</p>
            <div v-if="matchingAccountPage" class="row suggested-page" @click.prevent="selectFBAdAccountPageId(matchingAccountPage.id)">
              <div class="graphic">
                <img class="page-picture" :src="matchingAccountPage.picture.data.url" alt="ads" />
              </div>
              <a href="#" class="row-name" :title="matchingAccountPage.name">{{ matchingAccountPage.name }}</a>
              <input type="radio" class="input-position" :checked="selectedPageId === matchingAccountPage.id" />
            </div>

            <!-- All pages results -->
            <p v-if="matchingAccountPage" class="all-pages-label">Pages:</p>
            <div
              v-for="(page, index) in userAccountsListOrdered.filter((i) => {
                return currentFilterSearch ? i.name.toLowerCase().includes(currentFilterSearch.toLowerCase()) : i
              })"
              :key="index"
              class="row"
              @click.prevent="selectFBAdAccountPageId(page.id)"
            >
              <div class="graphic">
                <img class="page-picture" :src="page.picture.data.url" alt="ads" />
              </div>
              <a href="#" class="row-name" :title="page.name">{{ page.name }}</a>
              <input type="radio" class="input-position" :checked="selectedPageId === page.id" />
            </div>
          </div>

          <div v-else class="ads-selection">
            <div class="fb-log-error">
              <img src="@/assets/img/error-alert.svg" alt="alert" />
              <p>There is no valid adAccount pages for this Ad Account.</p>
            </div>
          </div>

          <button
            v-if="!selectedClient.FBBusinessManager.user_accounts_list.length"
            type="submit"
            class="bt back"
            @click.prevent="businessManagerPanelGoTo('select-ad-account')"
          >
            <div class="bt-text">
              <p>Back</p>
              <img alt="Plus" class="bt-img" src="@/assets/img/arrow-right.svg" />
            </div>
          </button>
          <button type="submit" class="bt" @click.prevent="syncSlectedPageId()">
            <div class="bt-text">
              <p>Next</p>
              <img alt="Plus" class="bt-img" src="@/assets/img/arrow-right.svg" />
            </div>
          </button>
        </div>

        <!-- Synch :: Select Adz to Sync -->
        <div v-if="businessManagerPanelStep === 'adz-selection'" class="panel">
          <Loader v-if="isLoading" :label="'Fetching your Business Manager informations'"></Loader>

          <header>
            <div class="title" @click.prevent="businessManagerPanelGoTo('fetchAdAccountInfos')">
              <img src="@/assets/img/back-icon.svg" alt="Back" />
              <p>Back</p>
            </div>
            <img src="@/assets/img/close.svg" alt="close" class="close" @click.prevent="toogleBusinessManagerPanel(false, 'reset')" />
          </header>

          <h2 class="small-title">Select your ads</h2>

          <div class="ads-selection">
            <div class="row" @click.prevent="syncSelectAllAdz(adzListReady)">
              <div class="graphic">
                <img src="@/assets/img/ads-icon.svg" alt="ads" />
              </div>
              <p class="row-name">All ads</p>
              <input type="checkbox" class="input-position" :checked="syncSelectedAdz.length === adzListReady.length" />
            </div>

            <div v-for="(adz, index) in adzListReady" :key="index" class="row" :class="{ ready: adz.isReady }" @click.prevent="syncSelectAdz(adz)">
              <div class="graphic img">
                <adz-image :adz="adz" :app-domain="appDomain"></adz-image>
              </div>
              <a href="#" class="row-name" :title="adz.name.trim()">
                {{ adz.name.trim() }}
                <small v-if="adz.type === 'multiple' && adzListReadyVideoCarousel.find(i => i.id === adz.id)"> - not supported</small>
              </a>
              <input type="checkbox" class="input-position" :checked="syncSelectedAdz.find((i) => i.id === adz.id)" />
            </div>
          </div>

          <div v-if="adzListReadyNoVideo.length !== adzListReady.length" class="fb-log-error video">
            <img src="@/assets/img/error-alert.svg" alt="alert" />
            <p>*Video ads can take up to 5 minutes to be uploaded to FBM</p>
          </div>

          <div v-if="adzListReadyVideoCarousel.length" class="fb-log-error video">
            <img src="@/assets/img/error-alert.svg" alt="alert" />
            <p>**Video Carousel ads are not supported</p>
          </div>

          <button type="submit" class="bt" @click.prevent="businessManagerPanelGoTo(2)">
            <div class="bt-text">
              <p>Next</p>
              <img alt="Plus" class="bt-img" src="@/assets/img/arrow-right.svg" />
            </div>
          </button>
        </div>

        <!-- Synch :: Select Campaigns -->
        <div v-if="businessManagerPanelStep === 2" class="panel">
          <header>
            <div class="title" @click.prevent="businessManagerPanelGoTo('adz-selection')">
              <img src="@/assets/img/back-icon.svg" alt="Back" />
              <p>Back</p>
            </div>
            <img src="@/assets/img/close.svg" alt="close" class="close" @click.prevent="toogleBusinessManagerPanel(false, 'reset')" />
          </header>

          <h2 class="small-title">Select a campaign</h2>

          <div class="search-filter-results">
            <input v-model="currentFilterSearch" type="text" class="search" placeholder="Search..." />
          </div>

          <div class="ads-selection">
            <div
              v-for="(item, index) in selectedClient.FBBusinessManager.campaigns.filter((i) => {
                return currentFilterSearch ? i.name.toLowerCase().includes(currentFilterSearch.toLowerCase()) : i
              })"
              :key="index"
              class="row"
              :class="{ 'is-active': item.status && item.status === 'ACTIVE' }"
              @click.prevent="setPushAdsToCampaignId(item.id)"
            >
              <div class="graphic">
                <img src="@/assets/img/campaign-icon.svg" alt="ads" />
              </div>
              <a href="#" class="row-name" :title="item.name.trim()">{{ item.name.trim() }}</a>
              <input type="checkbox" class="input-position" :checked="pushAdsToCampaignId === item.id" />
            </div>
            <div v-if="!selectedClient.FBBusinessManager.campaigns || !selectedClient.FBBusinessManager.campaigns.length" class="fb-log-error">
              <img src="@/assets/img/error-alert.svg" alt="alert" />
              <p>This Ad Account does not have any active campaigns. Please, create one or more campaigns to use this feature.</p>
            </div>
          </div>

          <button
            v-if="!selectedClient.FBBusinessManager.campaigns || !selectedClient.FBBusinessManager.campaigns.length"
            type="submit"
            class="bt back"
            @click.prevent="businessManagerPanelGoTo(1)"
          >
            <div class="bt-text">
              <p>Back</p>
              <img alt="Plus" class="bt-img" src="@/assets/img/arrow-right.svg" />
            </div>
          </button>
          <button
            v-if="selectedClient.FBBusinessManager.campaigns && selectedClient.FBBusinessManager.campaigns.length"
            type="submit"
            class="bt"
            @click.prevent="businessManagerPanelGoTo(3)"
          >
            <div class="bt-text">
              <p>Next</p>
              <img alt="Plus" class="bt-img" src="@/assets/img/arrow-right.svg" />
            </div>
          </button>
        </div>

        <!-- Synch :: Select AdsGroup -->
        <div v-if="businessManagerPanelStep === 3" class="panel">
          <header>
            <div class="title" @click.prevent="businessManagerPanelGoTo(2)">
              <img src="@/assets/img/back-icon.svg" alt="Back" />
              <p>Back</p>
            </div>
            <img src="@/assets/img/close.svg" alt="close" class="close" @click.prevent="toogleBusinessManagerPanel(false, 'reset')" />
          </header>

          <h2 class="small-title">Select an Ad Set</h2>

          <div class="search-filter-results">
            <input v-model="currentFilterSearch" type="text" class="search" placeholder="Search..." />
          </div>

          <div class="ads-selection">
            <div
              v-for="(item, index) in selectedClient.FBBusinessManager.adsets.filter((i) =>
                currentFilterSearch
                  ? i.campaign_id === pushAdsToCampaignId && i.name.toLowerCase().includes(currentFilterSearch)
                  : i.campaign_id === pushAdsToCampaignId
              )"
              :key="index"
              :class="{ 'is-active': item.status && item.status === 'ACTIVE', 'is-fade': new Date() > new Date(item.end_time) }"
              class="row"
              @click.prevent="setPushAdsToAdGroupId(item.id)"
            >
              <div class="graphic">
                <img src="@/assets/img/adset-icon.svg" alt="ads" />
              </div>
              <a href="#" class="row-name" :title="item.name.trim()">
                {{ item.name.trim() }}
                <br/>
                <small>End Time: {{ item.end_time }}</small>
              </a>
              <input type="checkbox" class="input-position" :checked="pushAdsToAdGroupId.find((i) => i === item.id)" />
            </div>
            <div v-if="!selectedClient.FBBusinessManager.adsets.filter((i) => i.campaign_id === pushAdsToCampaignId).length" class="fb-log-error">
              <img src="@/assets/img/error-alert.svg" alt="alert" />
              <p>This Ad Account does not have any active adsets. Please, create one or more adsets to use this feature.</p>
            </div>
          </div>

          <button
            v-if="!selectedClient.FBBusinessManager.adsets.filter((i) => i.campaign_id === pushAdsToCampaignId).length"
            type="submit"
            class="bt back"
            @click.prevent="businessManagerPanelGoTo(2)"
          >
            <div class="bt-text">
              <p>Back</p>
              <img alt="Plus" class="bt-img" src="@/assets/img/arrow-right.svg" />
            </div>
          </button>
          <button v-else type="submit" class="bt" @click.prevent="businessManagerPanelGoTo(4)">
            <div class="bt-text">
              <p>Next</p>
              <img alt="Plus" class="bt-img" src="@/assets/img/arrow-right.svg" />
            </div>
          </button>
        </div>

        <!-- Synch :: PUSH Adz -->
        <div v-if="businessManagerPanelStep === 4 || businessManagerPanelStep === 'pushAdzToBusinessManager'" class="panel">
          <Loader v-if="isLoading" :label="'Pushing Ads to your Business Manager Account'"></Loader>

          <header>
            <div class="title" @click.prevent="businessManagerPanelGoTo(3)">
              <img src="@/assets/img/back-icon.svg" alt="Back" />
              <p>Back</p>
            </div>
            <img src="@/assets/img/close.svg" alt="close" class="close" @click.prevent="toogleBusinessManagerPanel(false, 'reset')" />
          </header>

          <p v-if="error" class="fb-log-error warning">
            <img data-v-704f54dc="" src="/img/error-alert.ec494c8f.svg" alt="alert">
            <strong>Some configurations seems to be conflicting with the Facebook API for Business Manager. <br><br> Facebook returned : <br></strong>
            ({{ error.code }}) <br> {{ error.message }}
          </p>

          <h2 class="small-title">Do you confirm?</h2>

          <div class="row confirm-row-title">
            <div class="graphic">
              <img src="@/assets/img/ads-icon-dark.svg" alt="ads" />
            </div>
            <p>Selected Ads</p>
          </div>

          <div v-if="syncSelectedAdz && syncSelectedAdz.length" class="ads-selection">
            <div v-for="(adItem, index) in syncSelectedAdz" :key="index" class="row confirm-row">
              <div class="graphic img">
                <adz-image :adz="adItem" :app-domain="appDomain"></adz-image>
              </div>
              <a href="#" class="row-name cursor" :title="adItem.name.trim()">{{ adItem.name.trim() }}</a>
            </div>
          </div>

          <div class="row confirm-row-title">
            <div class="graphic">
              <img src="@/assets/img/campaign-icon-dark.svg" alt="ads" />
            </div>
            <p>Selected Ad Account</p>
          </div>

          <div class="ads-selection">
            <div class="row confirm-row">
              <div class="graphic">
                <img src="@/assets/img/account-icon.svg" alt="ads" />
              </div>
              <a
                href="#"
                class="row-name cursor"
                :title="selectedClient.FBBusinessManager.ad_accounts_list.find((i) => i.id === selectedClient.FBBusinessManager.ad_account_id).name"
              >
                {{ selectedClient.FBBusinessManager.ad_accounts_list.find((i) => i.id === selectedClient.FBBusinessManager.ad_account_id).name }}
              </a>
            </div>
          </div>

          <div class="row confirm-row-title">
            <div class="graphic">
              <img src="@/assets/img/campaign-icon-dark.svg" alt="ads" />
            </div>
            <p>Selected Facebook Page</p>
          </div>

          <div class="ads-selection">
            <div class="row confirm-row">
              <div class="graphic">
                <img
                  class="page-picture"
                  :src="
                    selectedClient.FBBusinessManager.user_accounts_list.find((i) => i.id === selectedClient.FBBusinessManager.page_id).picture.data.url
                  "
                  alt="ads"
                />
              </div>
              <a
                href="#"
                class="row-name cursor"
                :title="selectedClient.FBBusinessManager.user_accounts_list.find((i) => i.id === selectedClient.FBBusinessManager.page_id).name"
              >
                {{ selectedClient.FBBusinessManager.user_accounts_list.find((i) => i.id === selectedClient.FBBusinessManager.page_id).name }}
              </a>
            </div>
          </div>

          <div class="row confirm-row-title">
            <div class="graphic">
              <img src="@/assets/img/campaign-icon-dark.svg" alt="ads" />
            </div>
            <p>Selected Campaign</p>
          </div>

          <div class="ads-selection">
            <div class="row confirm-row">
              <div class="graphic">
                <img src="@/assets/img/campaign-icon.svg" alt="ads" />
              </div>
              <a href="#" class="row-name cursor" :title="selectedClient.FBBusinessManager.campaigns.find((i) => i.id === pushAdsToCampaignId).name">
                {{ selectedClient.FBBusinessManager.campaigns.find((i) => i.id === pushAdsToCampaignId).name }}
              </a>
            </div>
          </div>

          <div class="row confirm-row-title">
            <div class="graphic">
              <img src="@/assets/img/adset-icon-dark.svg" alt="ads" />
            </div>
            <p>Selected Ad set</p>
          </div>

          <div class="ads-selection">
            <div v-for="adGroupId in pushAdsToAdGroupId" :key="adGroupId" class="row confirm-row">
              <div class="graphic">
                <img src="@/assets/img/adset-icon.svg" alt="ads" />
              </div>
              <a href="#" class="row-name cursor" :title="selectedClient.FBBusinessManager.adsets.find((i) => i.id === adGroupId).name">{{
                selectedClient.FBBusinessManager.adsets.find((i) => i.id === adGroupId).name
              }}</a>
            </div>
          </div>

          <button type="submit" class="bt" @click.prevent="toogleBusinessManagerPanel(true, 'pushAdzToBusinessManager')">
            <div class="bt-text">
              <p>Confirm and launch synchronization</p>
              <img alt="Plus" class="bt-img" src="@/assets/img/arrow-right.svg" />
            </div>
          </button>
        </div>

        <!-- Synch :: SUCCESS Screen -->
        <div v-if="businessManagerPanelStep === 'success'" class="panel success-panel">
          <header>
            <div class="title">
              <img src="@/assets/img/business-manager.png" alt="Facebook Business Manager" />
              <p>Business Manager</p>
            </div>
            <img src="@/assets/img/close.svg" alt="close" class="close" @click.prevent="toogleBusinessManagerPanel(false, 'reset')" />
          </header>

          <h2 class="big-title">Success!</h2>

          <img src="@/assets/img/launch-fbm.svg" class="success-img" alt="Facebook Business Manager" />

          <a :href="'https://www.facebook.com/adsmanager/manage/campaigns?act=' + selectedFBAdAccountId.replace('act_', '')" target="_blank" class="bt">
            <div class="bt-text">
              <p>Go to your FBM account</p>
              <img alt="Plus" class="bt-img" src="@/assets/img/arrow-right.svg" />
            </div>
          </a>
        </div>
      </div>
    </div>

    <!-- Custom SWAL for GoogleExportCSV -->
    <GoogleExportCSVPopup
      :showCustomSwal="showCustomSwal"
      :isGeneratingCSV="isGeneratingCSV"
      :closeCustomSwal="closeCustomSwal"
      :getDownloadableCSV="getDownloadableCSV"
    />
  </section>
</template>

<script>
/*global FB*/

import { mapState, mapActions } from 'vuex'
import { isNil } from 'lodash'
import { functions, storage } from '@/firebase/init'
import { resetCommentsOnAdz, applyDuplicateIdRefs, doAlert } from '@/misc/helpers'

import Loader from '@/components/actions/Loader'
import ContextualToggleEdit from '@/components/actions/ContextualToggleEdit'
import AdzImage from '@/components/facebook/AdzImage'
import GoogleExportCSVPopup from '@/components/google/GoogleExportCSVPopup'

import AccountclientsDB from '@/firebase/account-clients-db'
import AccountClientCampaignsDB from '@/firebase/account-client-campaigns-db'
import AccountClientCampaignAdzDB from '@/firebase/account-client-campaign-adz-db'
import AccountClientCampaignGoogleAdzDB from '@/firebase/account-client-campaign-googleAdz-db'
// import ShareLinksDB from '@/firebase/shareLinks-db'

import ClientLogo from '@/components/clients/ClientLogo'

export default {
  head: function () {
    return {
      title: {
        inner: 'Campaign adz list',
      },
      meta: [
        {
          name: 'description',
          content: `${this.appTitle} Your campagin adz list`,
          id: 'desc',
        },
      ],
    }
  },
  components: {
    ContextualToggleEdit,
    ClientLogo,
    AdzImage,
    Loader,
    GoogleExportCSVPopup,
  },
  data() {
    return {
      adzList: null,
      adzListReady: [],
      adzListReadyNoVideo: [],
      adzListReadyVideoCarousel: [],
      googleAdzList: null,
      currentCampaign: null,
      selectedAdz: [], // For Sharing link
      openBusinessManagerPanel: false,
      businessManagerPanelStep: null,
      syncSelectedAdz: [],
      selectedFBAdAccountId: null,
      selectedPageId: null,
      pushAdsToCampaignId: null,
      pushAdsToAdGroupId: [],
      isLoading: false, // For API sync 'push' to Business Manager
      isGeneratingCSV: false, // For Google Export CSV generation
      showCustomSwal: false,
      error: '',
      currentFilterSearch: '',
      preferedViewMode: 'thumb',
      lastestGoogleAdsXslFile: null,
    }
  },
  computed: {
    clientId() {
      return this.$route.params.clientId
    },
    campaignId() {
      return this.$route.params.campaignId
    },
    adAccountsListOrdered() {
      if (!this.selectedClient.FBBusinessManager || !this.selectedClient.FBBusinessManager.ad_accounts_list.length) {
        return []
      }

      const result = [...this.selectedClient.FBBusinessManager.ad_accounts_list]
      const orderedResult = result.sort((a, b) => {
        if (a.name < b.name) {
          return -1
        }
        if (a.name > b.name) {
          return 1
        }
        return 0
      })

      return orderedResult
    },
    userAccountsListOrdered() {
      if (!this.selectedClient.FBBusinessManager || !this.selectedClient.FBBusinessManager.user_accounts_list.length) {
        return []
      }

      const result = [...this.selectedClient.FBBusinessManager.user_accounts_list]
      const orderedResult = result.sort((a, b) => {
        if (a.name < b.name) {
          return -1
        }
        if (a.name > b.name) {
          return 1
        }
        return 0
      })

      return orderedResult
    },
    matchingAccountPage() {
      if (!this.selectedFBAdAccountId || !this.adAccountsListOrdered.length) {
        return null
      }

      const matchName = this.adAccountsListOrdered.find((i) => i.id === this.selectedFBAdAccountId).name
      return this.selectedClient.FBBusinessManager.user_accounts_list.find((i) => i.name === matchName)
    },
    ...mapState('authentication', ['user']),
    ...mapState('app', ['appTitle', 'appDomain', 'platforms', 'cfGenerateGadz', 'cfDownloadGadz']),
    ...mapState('users', ['account', 'accountMembership', 'selectedClient', 'selectedCampaign']),
  },
  watch: {
    account: {
      async handler(account) {
        if (!isNil(account)) {
          // Redirect to selected ADz if the query is there
          if (this.$route.query.select) {
            const selectId = this.$route.query.select
            this.$router.push({
              name: 'CreateFacebookAds',
              params: {
                clientId: this.$route.params.clientId,
                campaignId: this.$route.params.campaignId,
                adzId: selectId,
              },
            })
            return
          }

          this.preferedViewMode = localStorage.getItem('preferedViewMode') ? localStorage.getItem('preferedViewMode') : 'thumb'

          // Refresh and show Adz List
          await this.refreshAdzList()
          this.setShareAdzList(null)
          this.resetInitClientFBBusiness()
          this.selectCampaign({
            clientId: this.$route.params.clientId,
            campaignId: this.$route.params.campaignId,
          })

          const accountClientCampaignsDbRef = new AccountClientCampaignsDB(this.account[0].id, this.$route.params.clientId, this.$route.params.campaignId)
          const fetchCampaign = await accountClientCampaignsDbRef.read(this.$route.params.campaignId)

          this.currentCampaign = fetchCampaign

          if (this.currentCampaign && this.currentCampaign.platforms.includes('googleAds') && this.googleAdzList.length) {
            try {
              const fileUrl = await storage.ref(`import-googleAdz-data-${this.$route.params.campaignId}.xlsx`)
              if (fileUrl) {
                this.lastestGoogleAdsXslFile = await fileUrl.getDownloadURL()
              }
            } catch (e) {
              this.error = e
            }
          }
        }
      },
      immediate: true,
    },
    openBusinessManagerPanel: {
      handler(openBusinessManagerPanel) {
        if (!openBusinessManagerPanel) {
          document.querySelector('body').style.overflow = ''
        } else {
          document.querySelector('body').style.overflow = 'hidden'
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.selectAdz({
      clientId: '',
      campaignId: '',
      adzId: null,
    })
  },
  methods: {
    async initFacebook() {
      window.fbAsyncInit = function() {
        FB.init({
          appId: "685821698917470", //You will need to change this
          cookie: true, // This is important, it's not enabled by default
          version: "v18.0"
        })
      }
    },

    async loadFacebookSDK(d, s, id) {
      let js = null
      const fjs = d.getElementsByTagName(s)[0];

      if (d.getElementById(id)) {
        return;
      }

      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    },

    async toogleBusinessManagerPanel(isOpen, action = '') {
      if (!action) {
        this.openBusinessManagerPanel = isOpen
        this.isLoading = false
      }

      if (action === 'getUserToken') {
        // console.log('getUserToken')

        this.isLoading = true
        this.openBusinessManagerPanel = isOpen
        this.businessManagerPanelStep = 'select-ad-account'

        // Start fresh...
        await this.loadFacebookSDK(document, "script", "facebook-jssdk");
        await this.initFacebook();
        FB.login(
          (response) => {
            // handle the response
            if (response.status && response.status === 'not_authorized') {
              this.isLoading = false
              this.openBusinessManagerPanel = false
              doAlert({
                title: `This Facebook account is not authorized to access any App`,
                type: 'error',
              })
              return
            }

            this.getUserToken(response)
          },
          {
            scope: 'ads_management,ads_read,business_management,pages_show_list,pages_read_engagement',
            return_scopes: true,
            enable_profile_selector: true,
            auth_type: 'rerequest',
          }
        )
      }

      if (action === 'fetchAdAccountInfos') {
        // console.log('fetchAdAccountInfos')

        this.isLoading = true
        this.openBusinessManagerPanel = isOpen
        this.businessManagerPanelStep = 'fetchAdAccountInfos'

        FB.getLoginStatus(async (response) => {
          // statusChangeCallback(response)

          if (response.status === 'unknown') {
            this.resetInitClientFBBusiness()

            this.isLoading = false
            this.openBusinessManagerPanel = false

            return
          }

          if (response.status === 'connected' && response.authResponse.accessToken && response.authResponse.userID) {
            // console.log({ userId: response.authResponse.userID, authToken: response.authResponse.accessToken })

            const AccountclientsDBRef = new AccountclientsDB(this.account[0].id)
            const updateClientInfos = await AccountclientsDBRef.read(this.$route.params.clientId)
            updateClientInfos.FBBusinessManager.access_token = response.authResponse.accessToken
            updateClientInfos.FBBusinessManager.user_id = response.authResponse.userID
            await AccountclientsDBRef.update(updateClientInfos)
            this.selectClient(this.$route.params.clientId)

            setTimeout(async () => {
              // Sync :: FB API Ad Account Get Campaings/AdGroups list
              try {
                const syncClientWithBusinessManagerAccount = functions.httpsCallable('syncClientWithBusinessManagerAccount')
                await syncClientWithBusinessManagerAccount({
                  accountId: this.account[0].id,
                  client: this.selectedClient,
                })
                this.selectClient(this.$route.params.clientId)
                this.isLoading = false
              } catch (e) {
                // console.log(e)
                this.isLoading = false
                // this.openBusinessManagerPanel = false
              }
            }, 755)
          }
        })
      }

      if (action === 'pushAdzToBusinessManager') {
        this.openBusinessManagerPanel = isOpen
        this.isLoading = true

        // console.log(this.pushAdsToCampaignId, this.pushAdsToAdGroupId, adsToPush)

        // Sync :: FB API Ad Account Get Campaings/AdGroups list
        try {
          const pushAdzToBusinessManagerAccount = functions.httpsCallable('pushAdzToBusinessManagerAccount', {timeout: 140000})
          const functionsResponse = await pushAdzToBusinessManagerAccount({
            accountId: this.account[0].id,
            client: this.selectedClient,
            campaignId: this.campaignId,
            selectedAdz: this.syncSelectedAdz,
            pushAdsToCampaignId: this.pushAdsToCampaignId,
            pushAdsToAdGroupId: this.pushAdsToAdGroupId,
          })

          // console.log('functionsResponse', functionsResponse)

          if (functionsResponse.data.error || !functionsResponse.data.success) {
            this.isLoading = false
            this.error = functionsResponse.data.error

            const sendNotificationToDevs = functions.httpsCallable('sendNotificationToDevs', {timeout: 140000})
            sendNotificationToDevs({
              errorObj: this.error,
              accountObj: this.account[0],
              userObj: this.user,
              selectedAdzObj: JSON.stringify(this.syncSelectedAdz)
            })
          } else {
            this.isLoading = false
            this.selectClient(this.$route.params.clientId)
            this.businessManagerPanelStep = 'success'
            this.error = ''
          }
        } catch (e) {
          this.isLoading = false
          this.error = e.data && e.data.error ? e.data.error.message : e
          // this.openBusinessManagerPanel = false

          const sendNotificationToDevs = functions.httpsCallable('sendNotificationToDevs', {timeout: 140000})
          sendNotificationToDevs({
            errorObj: this.error,
            accountObj: this.account[0],
            userObj: this.user,
            selectedAdzObj: JSON.stringify(this.syncSelectedAdz)
          })
        }
      }

      if (action === 'reset') {
        this.openBusinessManagerPanel = false
        this.isLoading = false
        this.resetInitClientFBBusiness()
      }
    },
    async getUserToken(response) {
      if (response.status === 'connected' && response.authResponse.accessToken && response.authResponse.userID) {
        // console.log({ userId: response.authResponse.userID, authToken: response.authResponse.accessToken })

        const AccountclientsDBRef = new AccountclientsDB(this.account[0].id)
        const updateClientInfos = await AccountclientsDBRef.read(this.$route.params.clientId)
        updateClientInfos.FBBusinessManager = {
          user_id: '',
          user_accounts_list: [],
          ad_accounts_list: [],
          adsets: [],
          access_token: '',
          ad_account_id: '',
          page_id: '',
          campaigns: [],
        }
        updateClientInfos.FBBusinessManager.access_token = response.authResponse.accessToken
        updateClientInfos.FBBusinessManager.user_id = response.authResponse.userID
        await AccountclientsDBRef.update(updateClientInfos)
        this.selectClient(this.$route.params.clientId)

        // console.log('FBAPI :: fetchFBUserInformations')

        setTimeout(async () => {
          // Sync :: FB API User Get AdsAccountList
          try {
            const fetchFBUserInformations = functions.httpsCallable('fetchFBUserInformations')
            await fetchFBUserInformations({ accountId: this.account[0].id, client: this.selectedClient })
            this.selectClient(this.$route.params.clientId)

            setTimeout(() => {
              this.isLoading = false
            }, 1244)
          } catch (e) {
            // console.log(e)
            this.isLoading = false
            // this.openBusinessManagerPanel = false
          }
        }, 755)
      }
    },
    businessManagerPanelGoTo(step) {
      this.businessManagerPanelStep = step
      this.currentFilterSearch = ''
    },
    selectFBAdAccountId(AdaAccountId) {
      this.selectedFBAdAccountId = AdaAccountId
    },
    selectFBAdAccountPageId(pageId) {
      this.selectedPageId = pageId
    },
    async syncSelectedAdAccountId() {
      this.isLoading = true
      // console.log(this.selectedFBAdAccountId)
      const AccountclientsDBRef = new AccountclientsDB(this.account[0].id)
      const updateClientInfos = await AccountclientsDBRef.read(this.$route.params.clientId)
      // updateClientInfos.FBBusinessManager.account_id = this.selectedFBAdAccountId
      updateClientInfos.FBBusinessManager.ad_account_id = this.selectedFBAdAccountId
      // updateClientInfos.FBBusinessManager.ad_account_name = this.selectedFBAdAccountId
      await AccountclientsDBRef.update(updateClientInfos)
      this.selectClient(this.$route.params.clientId)

      this.openBusinessManagerPanel = false
      this.businessManagerPanelStep = 'fetchAdAccountInfos'
      this.isLoading = false

      doAlert({
        title: `Business Manager connected successfully`,
        type: 'success',
      })

      this.toogleBusinessManagerPanel(true, 'fetchAdAccountInfos')
    },
    async syncSlectedPageId() {
      this.isLoading = true

      const AccountclientsDBRef = new AccountclientsDB(this.account[0].id)
      const updateClientInfos = await AccountclientsDBRef.read(this.$route.params.clientId)
      updateClientInfos.FBBusinessManager.page_id = this.selectedPageId
      await AccountclientsDBRef.update(updateClientInfos)
      this.selectClient(this.$route.params.clientId)

      this.isLoading = false
      this.businessManagerPanelStep = 'adz-selection'
    },
    setPushAdsToCampaignId(id) {
      this.pushAdsToCampaignId = id
    },
    setPushAdsToAdGroupId(id) {
      if (this.pushAdsToAdGroupId.find((i) => i === id)) {
        this.pushAdsToAdGroupId = this.pushAdsToAdGroupId.filter((i) => i !== id)
        return
      }
      this.pushAdsToAdGroupId.push(id)
    },
    async resetInitClientFBBusiness() {
      const AccountclientsDBRef = new AccountclientsDB(this.account[0].id)
      const updateClientInfos = await AccountclientsDBRef.read(this.$route.params.clientId)
      updateClientInfos.FBBusinessManager = {
        user_id: '',
        user_accounts_list: [],
        ad_accounts_list: [],
        adsets: [],
        access_token: '',
        ad_account_id: '',
        page_id: '',
        campaigns: [],
      }
      this.selectedAdz = []
      this.pushAdsToAdGroupId = []
      this.pushAdsToCampaignId = null
      this.selectedFBAdAccountId = null
      this.selectedPageId = null
      this.syncSelectedAdz = []
      await AccountclientsDBRef.update(updateClientInfos)
      this.selectClient(this.$route.params.clientId)
    },
    syncSelectAllAdz(adzList) {
      if (this.syncSelectedAdz.length === this.adzList.length) {
        this.syncSelectedAdz = []
        return
      }

      this.syncSelectedAdz = adzList
    },
    syncSelectAdz(adz) {
      if (this.adzListReadyVideoCarousel.find(i => i.id === adz.id)) {
        return
      }
      if (this.syncSelectedAdz.find((i) => i.id === adz.id)) {
        this.syncSelectedAdz = this.syncSelectedAdz.filter((i) => i.id !== adz.id)
        return
      }
      this.syncSelectedAdz.push(adz)
    },
    activeLink(adz) {
      // Push adz to array
      if (this.selectedAdz.includes(adz.id)) {
        const index = this.selectedAdz.indexOf(adz.id)
        this.selectedAdz.splice(index, 1)
      } else {
        this.selectedAdz.push(adz.id)
      }

      // Globally save adz selection for further creation of sharable link
      this.setShareAdzList({ ...this.selectedAdz })

      // Adz Ready to share ?
      if (this.selectedAdz.length) {
        this.selectAdzToShare(true)
      } else {
        this.selectAdzToShare(false)
      }
    },
    editAdz(adz) {
      this.$router.push({
        name: 'CreateFacebookAds',
        params: {
          clientId: this.selectedClient.id,
          campaignId: this.selectedCampaign.id,
          adzId: adz.id,
        },
      })
    },
    archiveAdz(adz) {
      this.$swal({
        title: 'Archive',
        text: `Are you sure you want to archive ${adz.name}?`,

        showCloseButton: true,
        showCancelButton: true,
        imageUrl: '/img/icons/archive.svg',
        confirmButtonColor: '#4628FF',
        cancelButtonColor: '#637082',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.value) {
          const accountClientCampaignAdzDvbRef = new AccountClientCampaignAdzDB(this.account[0].id, this.selectedClient.id, this.selectedCampaign.id)
          const newAdz = { ...adz, isArchived: true }

          accountClientCampaignAdzDvbRef.update(newAdz)

          doAlert({ title: `${adz.name.trim()} archived`, type: 'success' })

          this.refreshAdzList()
        }
      })
    },
    deleteAdz(adz) {
      this.$swal({
        title: 'Delete',
        text: `Are you sure you want to delete ${adz.name}?`,

        showCloseButton: true,
        showCancelButton: true,
        imageUrl: '/img/icons/delete.svg',
        confirmButtonColor: '#4628FF',
        cancelButtonColor: '#637082',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.value) {
          const accountClientCampaignAdzDvbRef = new AccountClientCampaignAdzDB(this.account[0].id, this.selectedClient.id, this.selectedCampaign.id)
          accountClientCampaignAdzDvbRef.delete(adz.id)
          doAlert({ title: `${adz.name.trim()} deleted`, type: 'success' })

          this.refreshAdzList()
        }
      })
    },
    duplicateAdz(adz) {
      if (this.selectedCampaign.nbAdz >= this.accountMembership.config.adz) {
        this.$router.push({ name: this.$route.name, query: { membershipGuard: true } })
        return
      }
      this.$swal({
        title: 'Duplicate',
        text: `Are you sure you want to duplicate ${adz.name.trim()}?`,

        showCloseButton: true,
        showCancelButton: true,
        imageUrl: '/img/icons/duplicate.svg',
        confirmButtonColor: '#4628FF',
        cancelButtonColor: '#637082',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.value) {
          const accountClientCampaignAdzDvbRef = new AccountClientCampaignAdzDB(this.account[0].id, this.selectedClient.id, this.selectedCampaign.id)
          let newAdz = { ...adz }
          newAdz.name = `${newAdz.name} (copy)`
          newAdz.name = newAdz.name.trim()
          newAdz = applyDuplicateIdRefs(newAdz)
          delete newAdz.id
          accountClientCampaignAdzDvbRef.create(resetCommentsOnAdz(newAdz))
          doAlert({ title: `${adz.name.trim()} duplicated`, type: 'success' })

          this.refreshAdzList()
        }
      })
    },
    async exportGoogleAdz() {
      this.isGeneratingCSV = true
      this.showCustomSwal = true

      const credentials = {
        accountId: this.account[0].id,
        clientId: this.$route.params.clientId,
        campaignId: this.$route.params.campaignId,
      }
      const generateGoogleAdsEditorCSV = functions.httpsCallable('generateGoogleAdsEditorCSV')
      await generateGoogleAdsEditorCSV(credentials)
      this.isGeneratingCSV = false
    },
    getDownloadableCSV() {
      const credentials = {
        accountId: this.account[0].id,
        clientId: this.$route.params.clientId,
        campaignId: this.$route.params.campaignId,
      }
      window.open(`${this.cfDownloadGadz}import-googleads-editor-${credentials.campaignId}.csv?alt=media`)
      this.showCustomSwal = false
    },
    async refreshAdzList() {
      const accountClientCampaignAdzDbRef = new AccountClientCampaignAdzDB(this.account[0].id, this.$route.params.clientId, this.$route.params.campaignId)
      this.adzList = await accountClientCampaignAdzDbRef.readAll(null, false)

      this.adzListReady = this.adzList.filter((i) => i.isReady)

      let adzListReadyNoVideo = this.adzListReady.filter((i) => !i.content.videoMp4)
      adzListReadyNoVideo = adzListReadyNoVideo.filter((i) => {
        let isNotVideo = true
        if (i.contentSlider && i.contentSlider.length) {
          i.contentSlider.forEach(function (slide) {
            isNotVideo = !slide.videoMp4
          })
        }
        return isNotVideo
      })
      this.adzListReadyNoVideo = adzListReadyNoVideo

      const carouselAdz = this.adzListReady.filter((i) => i.type === 'multiple')
      this.adzListReadyVideoCarousel = carouselAdz.filter((i) => {
        let isVideo = false
        if (i.contentSlider && i.contentSlider.length) {
          i.contentSlider.forEach(function (slide) {
            isVideo = (isVideo || !isVideo && slide.videoMp4) ? true : false
          })
        }
        return isVideo
      })

      this.adzListReadyVideoCarousel.forEach((check) => {
        this.adzListReady = this.adzListReady.filter(i => i.id !== check.id)
      })

      const accountClientCampaignGoogleAdzDbRef = new AccountClientCampaignGoogleAdzDB(
        this.account[0].id,
        this.$route.params.clientId,
        this.$route.params.campaignId
      )
      this.googleAdzList = await accountClientCampaignGoogleAdzDbRef.readAll(null, false)
      this.googleAdzList = this.googleAdzList.filter(function (item) {
        return item.name !== 'All'
      })
    },
    async saveLiveEdit(e) {
      // console.log('Closing and Saving liveEdit()')

      const target = e.target

      // console.log(target.getAttribute('contenteditable'))

      if (target.getAttribute('contenteditable')) {
        // console.log('Yeah!')

        const accountClientCampaignsDbRef = new AccountClientCampaignsDB(this.account[0].id, this.$route.params.clientId, this.$route.params.campaignId)

        const updateCampaign = {
          ...this.currentCampaign,
          name: target.textContent.trim(),
        }

        await accountClientCampaignsDbRef.update(updateCampaign)

        target.removeAttribute('contenteditable')

        // Refresh State
        this.selectCampaign({
          clientId: this.$route.params.clientId,
          campaignId: this.$route.params.campaignId,
        })
      }
    },
    getAdzTotalComments(adz) {
      let nbComments = 0

      if (adz.type === 'single') {
        nbComments += adz.content && adz.content.commentMarkers ? adz.content.commentMarkers.length : 0
      } else if (adz.contentSlider) {
        nbComments += adz.contentSlider.reduce((counter, slide) => {
          return counter + (slide.commentMarkers && slide.commentMarkers.length ? slide.commentMarkers.length : 0)
        }, 0)
      }

      nbComments += adz.headerCommentMarkers ? adz.headerCommentMarkers.length : 0
      nbComments += adz.footerCommentMarkers ? adz.footerCommentMarkers.length : 0

      return nbComments
    },
    closeCustomSwal() {
      this.showCustomSwal = false
    },

    showAdzList() {
      this.preferedViewMode = 'list'
      localStorage.setItem('preferedViewMode', this.preferedViewMode)
    },
    showAdzThumbnail() {
      this.preferedViewMode = 'thumb'
      localStorage.setItem('preferedViewMode', this.preferedViewMode)
    },
    ...mapActions('app', ['selectAdzToShare', 'enterLiveEditMode']),
    ...mapActions('users', ['selectClient', 'selectCampaign', 'selectAdz', 'setShareAdzList', 'refreshCampaign']),
  },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
@import '@/theme/typography.scss';
@import '@/theme/button.scss';
@import '@/theme/page-title.scss';

.toggle-options {
  .options-toggle {
    display: none;
  }
}

.still-no-ad {
  width: 100%;
  background-color: #fff;
  border: 1px solid #e0e2e6;
  border-radius: 3px;
  padding: 30px 15px;
  text-align: center;
  p {
    color: $dark-grey;
  }
}

.platform-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 25px;
  margin-top: 50px;

  color: $dark-grey;
  font-size: 14px;
  font-weight: 600;
}

.right {
  display: flex;
  align-items: center;

  .download-icon {
    width: 17px;
    margin-right: 20px;
    opacity: 0.5;
    transition: all 0.2s ease-in-out;

    &:hover {
      opacity: 1;
    }
  }
}

.platform {
  display: flex;
  align-items: baseline;
  align-items: center;

  .export-link {
    color: $purple-blue;
    font-size: 16px;
    font-weight: 600;
  }

  img {
    width: auto;
    height: 35px;
    margin-right: 15px;
  }

  .spacer {
    margin: 0 10px;
  }

  .business-manager-button {
    background-color: #fff;
    border: 1px solid #e0e2e6;
    border-radius: 3px;
    display: flex;
    overflow: hidden;
    margin-left: 15px;
    cursor: pointer;

    .state {
      display: flex;
      align-items: center;
      padding: 10px 15px;
      img {
        width: 20px;
        margin-right: 10px;
      }
      p {
        font-size: 13px;
      }
      .check {
        background: linear-gradient(to right, #2bb0c5, #6bd8ba);
        width: 20px;
        height: 20px;
        border-radius: 10px;
        display: none;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
        img {
          width: 10px;
          margin-right: 0;
        }
      }
    }

    .action {
      background: linear-gradient(to right, $dark-grey, $dark-grey);
      padding: 10px 15px;
      transition: all 0.4s ease;
      p {
        color: #fff;
        font-size: 13px;
        &.connect {
          display: block;
        }
        &.push {
          display: none;
        }
      }
    }

    &:hover {
      .action {
        background: linear-gradient(to right, #2bb0c5, #6bd8ba);
      }
    }

    &.connected {
      .state {
        .check {
          display: flex;
        }
      }
      .action {
        p {
          &.connect {
            display: none;
          }
          &.push {
            display: block;
          }
        }
      }
    }
  }
}

.business-manager-synch-panel {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000000;
  width: 100vw;
  height: 100vh;
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    background-color: $dark-grey;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    z-index: 100;
  }
  .panel {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1000;
    width: 33vw;
    height: 100vh;
    background-color: #f9f9fb;
    padding: 2vw 2vw 7vw 2vw;
    overflow-y: scroll;

    p {
      color: $dark-grey;
    }

    .bt {
      width: 33vw;
      position: fixed;
      right: 0;
      border-radius: 0;
      bottom: 0;
      z-index: 2;
      p {
        color: #fff;
      }
    }

    &.success-panel {
      text-align: center;

      .bt {
        display: inline-block;
      }

      .big-title {
        margin: 1.5vw 0;
      }
    }

    header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .title {
        display: flex;
        align-items: center;
        cursor: pointer;

        img {
          width: 25px;
          margin-right: 10px;
        }

        p {
          font-weight: 600;
          font-size: 15px;
          color: $dark-grey;
        }
      }

      .close {
        width: 13px;
        cursor: pointer;
      }
    }

    .small-title {
      font-weight: 300;
      font-size: 1.8vw;
      width: 100%;
      text-align: center;
      margin: 3vw 0 1.5vw 0;
      color: $dark-grey;
    }

    .ads-selection {
      width: 100%;
    }

    .fb-log-error {
      color: $dark-grey;
      width: 100%;
      text-align: center;

      img {
        display: inline-block;
        width: 50px;
        margin-bottom: 20px;
      }

      &.video {
        display: flex;
        margin-top: 20px;

        img {
          width: 40px;
          margin-bottom: 0;
        }

        p {
          text-align: left;
          padding-left: 20px;
          width: 70%;
          font-size: 14px;
        }
      }
    }

    .fb-log-error.warning {
      padding: 25px 10px;
      border: 1px solid #f9ba34;
      background: rgba(#fff, 0.45);
      font-size: 14px;
      margin-top: 25px;
      margin-bottom: -25px;
      text-align: center;
      border-radius: 4px;

      img {
        display: block;
        max-width: 45px;
        margin: 0 auto;
        margin-bottom: 10px;
      }
    }

    .bt {
      margin: 0 auto;
      margin-top: 3vw;
    }

    .success-img {
      width: 80%;
      margin: 0 auto;
    }

    .search-filter-results {
      width: 100%;
      margin-bottom: 10px;

      .search {
        width: 100%;
        height: 45px;
        border: 0;
        background-color: #eaebef;
        border-radius: 3px;
        background-image: url(../../assets/img/search-icon.svg);
        background-repeat: no-repeat;
        background-position: 12px center;
        padding-left: 60px;
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
      }
    }

    .all-pages-label {
      font-size: 13px;
      margin: 0;
      padding: 10px 0 10px 0;
      color: #637082;
    }

    .suggested-label {
      font-size: 13px;
      margin: 0;
      padding: 15px 0 10px 0;
      color: #637082;
    }

    .row {
      width: 100%;
      min-height: 45px;
      background-color: #fff;
      display: flex;
      align-items: center;
      position: relative;
      margin-bottom: 10px;
      border: 1px solid #e0e2e6;
      border-radius: 3px;
      overflow: hidden;
      cursor: pointer;
      transition: all 0.2s ease;

      &.confirm-row {
        cursor: default;
        margin-bottom: 8px;

        .graphic {
          background: $dark-grey;
          img {
            width: 18px;
          }
        }

        &:hover {
          transform: none;
          background-color: #fff;
        }
      }

      &.confirm-row-title {
        cursor: default;
        background-color: transparent;
        border: 0;
        border-radius: 0;
        margin-bottom: 5px;
        margin-top: 15px;
        min-height: 25px;
        p {
          color: #6934ff;
          font-weight: 600;
          font-size: 13px;
          margin-left: 0;
        }
        .graphic {
          background-color: transparent;
          display: none;
          img {
            width: 22px;
          }
        }

        &:hover {
          transform: none;
          background-color: transparent;
        }
      }

      &.is-active {
        .graphic {
          background-color: #46c1c0;
        }
      }

      .graphic {
        width: 45px;
        height: 45px;
        background-color: $dark-grey;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 20px;
        }

        img.page-picture {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-right: 1px solid #e0e2e6;
        }

        &.img {
          img {
            width: 100%;
          }
        }
      }

      .row-name {
        position: relative;
        z-index: 2;
        width: 100%;
        font-weight: 400;
        font-size: 14px;
        color: $dark-grey;
        margin-left: 15px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        &.cursor {
          cursor: default;
        }
      }

      .input-position {
        position: absolute;
        top: 14px;
        right: 20px;
        z-index: 1;
      }

      &:hover {
        transform: scale(0.99, 0.99);
        background-color: #f9f9fb;
      }

      &.is-fade {
        opacity: 0.45;
      }
    }
  }
  &.is-loading {
    .panel {
      overflow: hidden;
    }
  }
}

.export {
  &.link-style {
    color: $purple-blue;
    font-weight: 600;
    cursor: pointer;
  }
}

.wrap-right {
  display: flex;
  align-items: center;

  .show-type {
    width: 17px;
    margin-right: 20px;
    opacity: 0.4;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover,
    &.actif {
      opacity: 1;
    }
  }
}

.add-format {
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    .add {
      transform: scale(1.2);
    }
  }

  .link-style {
    color: $purple-blue;
  }

  p {
    color: $dark-grey;
    font-weight: 600;
  }

  .add {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 20px;
    margin-left: 10px;
    background: linear-gradient(to bottom, #2e7df2, #8a23f1);
    transition: all 0.2s ease-in-out;

    img {
      width: 10px;
      height: 10px;
      display: block;
    }
  }
}

.ads-wrap {
  margin-bottom: 50px;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;

  &.google-ads-wrap {
    .ad-wrap {
      width: 100%;
      overflow: hidden;
      .infos {
        position: relative;
        display: flex;
        background-color: #fff;
        padding: 20px;
        cursor: pointer;
        &:hover {
          .preview-google-ad {
            img {
              opacity: 1;
            }
          }
        }
        .campaing-infos {
          display: flex;
          width: 25%;

          &.name {
            width: 50%;
          }
        }
        .preview-google-ad {
          position: absolute;
          right: 20px;
          top: 20px;
          display: flex;
          align-items: center;
          img {
            opacity: 0.5;
            height: 15px;
            width: auto;
            transition: all 0.2s ease-in-out;
          }
        }
        p {
          color: $dark-grey;
          font-weight: 600;
          font-size: 14px;
          margin-left: 5px;
        }
      }
    }
  }

  .ad-wrap {
    width: calc(25% - 20px);
    border: 1px solid #e0e2e6;
    border-radius: 3px;
    margin: 0 10px;
    margin-bottom: 20px;
    &.active {
      .over-wrap {
        opacity: 1 !important;

        .delete,
        .dupplicate,
        .actions-wrap {
          display: none;
        }
      }
    }

    &.ready {
      .share-selection {
        display: block !important;
      }
      .action:first-child {
        display: block !important;
        margin-bottom: 10px;
      }
    }

    .ad-img-wrap {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      height: 283px;
      background-color: #fff;

      &:hover {
        .over-wrap {
          opacity: 1;
        }
      }

      .img-wrap {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        overflow: hidden;
      }

      .over-wrap {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 5;
        width: 100%;
        height: 100%;
        background-color: rgba(46, 53, 65, 0.9);
        transition: all 0.3s ease-in-out;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .share-selection {
        position: absolute;
        left: 20px;
        top: 20px;
        width: 24px;
        height: 24px;
        border-radius: 12px;
        text-align: center;
        cursor: pointer;
        border: 1px solid #fff;
        display: none;

        img {
          width: 14px;
          position: relative;
          top: 6px;
          left: 4px;
          opacity: 0;
        }

        &.active {
          background-color: #fff;
          img {
            opacity: 1;
          }
        }
      }

      .delete {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 18px;
        height: auto;
        cursor: pointer;
      }

      .dupplicate {
        position: absolute;
        top: 20px;
        right: 55px;
        width: 20px;
        height: auto;
        cursor: pointer;
      }

      .ad-img,
      .ad-video {
        width: 100%;
        height: auto;
        display: block;
      }

      .actions-wrap {
        position: relative;
        width: 120px;
        z-index: 5;
        .action {
          width: 120px;
          text-align: center;
          padding: 12px 25px;
          border-radius: 3px;
          color: #fff;
          font-size: 14px;
          font-weight: 600;
          display: inline-block;
          background-color: rgba(255, 255, 255, 0.1);
          border: 1px solid #fff;
          transition: all 0.2s ease-in-out;
          &:hover {
            background-color: rgba(255, 255, 255, 1);
            color: $dark-grey;
          }
          &:first-child {
            display: none;
          }
        }
      }
    }

    footer {
      background-color: #fff;
      border-top: 1px solid #e0e2e6;
      height: 60px;
      padding: 10px 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .infos {
        max-width: 180px;
        color: $dark-grey;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;

        .campaing-name {
          width: 100%;
          font-size: 14px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        .campaing-ads {
          font-size: 13px;
          opacity: 0.5;
          padding-top: 3px;
        }

        .comments {
          display: flex;
          align-items: center;

          p {
            font-size: 12px;
            color: $purple-blue;
          }

          svg {
            margin-right: 5px;
          }

          &.fade {
            display: none;
          }
        }
      }

      .right {
        display: flex;

        .tool-tip-wrap {
          display: none;
        }

        .action-list {
          display: none;
        }
      }

      .ad-type {
        margin-left: 10px;

        img {
          width: auto;
          height: 25px;
        }
      }

      .status {
        display: flex;
        align-items: center;

        .approved {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 22px;
          height: 22px;
          border-radius: 11px;
          background: linear-gradient(to right, #2bb0c5, #6bd8ba);

          img {
            width: 8px;
          }
        }
      }
    }
  }

  &.displaylist {
    .ad-wrap {
      width: 100%;
      display: flex;
      margin-bottom: 10px;

      .ad-img-wrap {
        width: 60px;
        height: 60px;

        .over-wrap {
          display: none;
        }

        &:hover {
          .over-wrap {
            opacity: 0;
          }
        }
      }

      footer {
        width: 100%;
        border: 0;
        position: relative;

        .infos {
          padding-left: 45px;
          max-width: none;
        }

        .status {
          margin-right: 10px;
        }

        .right {
          align-items: center;

          .ad-type {
            margin-left: 0;
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            left: 15px;
          }

          .tool-tip-wrap {
            display: block;
          }

          .action-list-wrap {
            display: flex;
            align-items: center;
            cursor: pointer;

            img {
              width: auto;
              height: 15px;
              margin: 0 10px;
              opacity: 0.5;
              transition: all 0.2s ease;

              &:hover {
                opacity: 1;
              }
            }
          }

          .action-list {
            display: block;
          }

          .action {
            margin-bottom: 0;
          }

          .tool-tip-wrap {
            position: relative;
            &:hover {
              .tool-tip {
                opacity: 1;
                top: -40px;
                z-index: 1;
                display: block;
              }
            }
          }

          .tool-tip {
            opacity: 0;
            position: absolute;
            top: -50px;
            left: 50%;
            transform: translate(-50%, 0);
            padding: 4px 6px;
            border-radius: 3px;

            background-color: $dark-blue;
            font-size: 12px;
            font-weight: 400;
            color: #fff;

            transition: all 0.2s ease-in-out;

            z-index: -10;
            display: none;

            &:after {
              position: absolute;
              bottom: -5px;
              left: calc(50% - 5px);
              transform: rotate(45deg);
              content: '';
              display: block;
              width: 10px;
              height: 10px;
              background-color: $dark-blue;
            }
          }
        }
      }
    }
  }
}

video {
  position: relative;
  width: 100%;
  display: block;
}
</style>
