<template>
  <div class="custom-swal-container custom-swal-center custom-swal" :class="{ 'custom-swal-shown': showCustomSwal }" @click.prevent="closeCustomSwal()">
    <div
      aria-labelledby="custom-swal-title"
      aria-describedby="custom-swal-content"
      class="custom-swal-popup custom-swal-modal custom-swal-show"
      tabindex="-1"
      role="dialog"
      aria-live="assertive"
      aria-modal="true"
      style="display: flex"
      @click.prevent.stop
    >
      <div class="custom-swal-header">
        <div class="custom-swal-icon custom-swal-warning export-state custom-swal-animate-warning-icon" style="display: flex"></div>
        <h2 id="custom-swal-title" class="custom-swal-title" style="display: flex">Google Ads Editor</h2>
        <button type="button" class="custom-swal-close" aria-label="Close this dialog" style="display: flex" @click.prevent="closeCustomSwal()">×</button>
      </div>
      <div class="custom-swal-content">
        <div id="custom-swal-content" style="display: block">Export your ads and easily import it in Editor</div>
      </div>
      <div class="custom-swal-btns">
        <button type="submit" class="bt" :class="{ 'is-loading': isGeneratingCSV }" @click.prevent.stop="getDownloadableCSV()">
          <div class="bt-text">
            <p>Download your CSV</p>
            <img alt="Plus" class="bt-img" src="@/assets/img/arrow-right.svg" />
          </div>
          <p class="loader">
            &nbsp;
            <svg class="main-loader" width="38" height="38" xmlns="http://www.w3.org/2000/svg" stroke="#EFF1F2">
              <g transform="translate(1 1)" stroke-width="2" fill="none" fill-rule="evenodd">
                <circle stroke-opacity=".5" cx="18" cy="18" r="18" />
                <path d="M36 18c0-9.94-8.06-18-18-18">
                  <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="1s" repeatCount="indefinite" />
                </path>
              </g>
            </svg>
            &nbsp;
          </p>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showCustomSwal: {
      type: Boolean,
      default: false,
    },
    isGeneratingCSV: {
      type: Boolean,
      default: false,
    },
    closeCustomSwal: {
      type: Function,
      required: true,
    },
    getDownloadableCSV: {
      type: Function,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
@import '@/theme/typography.scss';
@import '@/theme/button.scss';
@import '@/theme/customSwalPopup.scss';
</style>
