<template>
  <div class="loader-wrap">
    <img src="@/assets/img/pastille.svg" alt="Showadz" class="pastille" />
    <svg class="main-loader" width="38" height="38" xmlns="http://www.w3.org/2000/svg" stroke="#4628FF">
      <g transform="translate(1 1)" stroke-width="1" fill="none" fill-rule="evenodd">
        <circle stroke-opacity=".5" cx="18" cy="18" r="18" />
        <path d="M36 18c0-9.94-8.06-18-18-18">
          <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="1s" repeatCount="indefinite" />
        </path>
      </g>
    </svg>
    <p v-if="label" class="loading-label">{{ label }}</p>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: false,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

.loader-wrap {
  position: fixed;
  top: 0;
  right: 0;
  width: 33vw;
  height: 100vh;
  background-color: #f9f9fb;
  z-index: 10;
}

.main-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(5);
  z-index: 1000;
}

.pastille {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 150px;
  height: auto;
  z-index: 1000;
}

.loading-label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 100px);
  color: $dark-blue;
  font-size: 18px;
  font-weight: 300;
  text-align: center;
}
</style>
